















































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { OptionModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseListArInvoice } from "@/models/interface/invoice.interface";
import { DataWarehouseLocation } from "@/models/interface/logistic.interface";
import {
  DeliveryOrderData,
  SalesOrderDataTracking,
} from "@/models/interface/salesOrder.interface";
import {
  RequestReturnFromCustomer,
  ReturnItems,
} from "@/models/interface/SalesReturn.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { contactServices } from "@/services/contact.service";
import { deliveryOrderServices } from "@/services/deliveryorder.service";
import { invoiceServices } from "@/services/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { salesOrderServices } from "@/services/salesorder.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import Decimal from "decimal.js-light";
import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";

interface Dropdown {
  key?: number;
  name?: string;
  id?: string;
  available?: number;
}

enum RETURN_FROM {
  DELIVERY_ORDER = "DELIVERY_ORDER",
  INVOICE = "INVOICE",
}

enum REFUND_OPTIONS {
  REFUND = "REFUND",
  CREDIT_MEMO = "CREDIT_MEMO",
}

@Component
export default class ReturnFromCustomer extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  mode = "" as string;
  disableData = false as boolean;
  dataFind = {
    branchId: "" as string,
    customerId: "" as string,
    deliveryOrderId: "" as string,
    invoiceARId: "" as string,
  };
  columnsTable = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      key: "productCode",
      scopedSlots: { customRender: "isNull" },
      responsiveColInput: [
        {
          name: "qtyReturn",
          placeholder: "",
          disabled: "",
          style: "width: 100%",
        },
      ],
      responsiveColSelect: [
        {
          name: "locationReceived",
          style: "width: 100%;",
          disabled: false,
          value: "id",
          options: [] as any[],
          loading: false,
        },
      ],
    },
    {
      title: this.$t("lbl_qty_develivered_invoice"),
      dataIndex: "qtyDelivery",
      key: "qtyDelivery",
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_available_return"),
      dataIndex: "qtyAvailableReturn",
      key: "qtyAvailableReturn",
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_qty_return"),
      dataIndex: "qtyReturn",
      key: "qtyReturn",
      width: 200,
      scopedSlots: { customRender: "qtyReturn" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      key: "total",
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_location_received"),
      dataIndex: "locationReceived",
      key: "locationReceived",
      width: 300,
      scopedSlots: { customRender: "locationReceived" },
    },
  ] as ColumnTableCustom[];
  loading = {
    table: false,
    save: false,
    documentNumber: false,
  };
  journalNumber = {
    name: "" as string,
    id: "" as string,
  };
  calculationData = 0 as number;
  dataListItems = [] as any[];
  formFind!: WrappedFormUtils;
  formDetail!: WrappedFormUtils;
  dataListSalesOrder = [] as SalesOrderDataTracking[];
  disableDataFind = true as boolean;
  formRuleFind = {
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    returnFrom: {
      label: "lbl_return_from",
      name: "returnFrom",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "returnFrom",
        {
          rules: [],
        },
      ],
    },
    documentNumber: {
      label: "lbl_document_number",
      name: "documentNumber",
      placeholder: "lbl_document_number_placeholder",
      decorator: [
        "documentNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  formRuleResult = {
    notes: {
      label: "lbl_notes",
      name: "notes",
      placeholder: "lbl_notes",
      decorator: ["notes"],
    },
    custName: {
      label: "lbl_customer_name",
      name: "customername",
      placeholder: "lbl_customer_name",
      decorator: [
        "custName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    journalNumber: {
      label: "lbl_journal_number",
      name: "journalNumber",
      placeholder: "lbl_journal_number",
      decorator: [
        "journalNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    salesOrderNumber: {
      label: "lbl_sales_order_number",
      name: "salesOrderNumber",
      placeholder: "lbl_sales_order_number",
      decorator: [
        "salesOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    returnNumber: {
      label: "Return Number",
      name: "returnnumber",
      placeholder: "Return Number",
      decorator: ["returnNumber"],
    },
    returnDate: {
      label: "lbl_return_date",
      name: "returndate",
      placeholder: DEFAULT_DATE_FORMAT,
      decorator: [
        "returnDate",
        {
          initialValue: moment(),
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    deliveryOrderNumber: {
      label: "lbl_delivery_order_number",
      name: "deliveryOrderNumber",
      placeholder: "lbl_delivery_order_number",
      decorator: [
        "deliveryOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoicenumber",
      placeholder: "lbl_invoice_number",
      show: false,
      decorator: [
        "invoiceNumber",
        {
          rules: [],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch",
      decorator: [
        "branch",
        {
          rules: [],
        },
      ],
    },
    deliveryOrderDate: {
      label: "lbl_delivery_order_date",
      name: "deliveryOrderDate",
      placeholder: DEFAULT_DATE_FORMAT,
      decorator: [
        "deliveryOrderDate",
        {
          rules: [],
        },
      ],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: DEFAULT_DATE_FORMAT,
      show: false,
      decorator: [
        "invoiceDate",
        {
          rules: [],
        },
      ],
    },
    refundOption: {
      label: "lbl_refund_option",
      name: "refundOption",
      show: false,
      decorator: [
        "refundOption",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  customerOption = {
    data: [] as ContactData[],
    search: "",
    fetching: true,
  };
  dataDoOrInvoiceNumber = [] as
    | DeliveryOrderData[]
    | DataResponseListArInvoice[];

  returnFromOption = {
    data: [
      {
        label: `lbl_${RETURN_FROM.DELIVERY_ORDER.toLowerCase()}`,
        value: RETURN_FROM.DELIVERY_ORDER,
      },
      {
        label: `lbl_${RETURN_FROM.INVOICE.toLowerCase()}`,
        value: RETURN_FROM.INVOICE,
      },
    ] as OptionModel[],
  };

  refundOptions: OptionModel[] = [
    {
      label: `lbl_${REFUND_OPTIONS.REFUND.toLowerCase()}`,
      value: REFUND_OPTIONS.REFUND,
    },
    {
      label: `lbl_${REFUND_OPTIONS.CREDIT_MEMO.toLowerCase()}`,
      value: REFUND_OPTIONS.CREDIT_MEMO,
    },
  ];
  @Watch("calculationData")
  public changeCalculationData(data) {
    if (data > 0) {
      this.dataListItems.forEach(item => {
        item.total = item.price * item.qtyReturn;
      });
    }
  }
  changeCustomer(value) {
    this.formFind.setFieldsValue({
      documentNumber: "",
      customerName: value,
    });
    switch (this.formFind.getFieldValue("returnFrom")) {
      case RETURN_FROM.DELIVERY_ORDER:
        this.getDoNumber(value, "init");
        break;
      case RETURN_FROM.INVOICE:
        this.getInvoiceNumber(value, "init");
        break;
      default:
        break;
    }
  }

  /**
   * @param value document number | customer id
   * @param type init | search
   * value as document number if type search
   * value as customer id if type ini
   */
  getInvoiceNumber(value: string, type: "init" | "search"): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const defaultQ: string[] = [
      builder
        .push(["status", "Fully Paid"], { like: "both" })
        .or()
        .push(["status", "Partial Paid"], { like: "both" })
        .and()
        .push(["invoiceSource", "Product Sale"], { like: "both" })
        .build(),
    ];

    if (type === "search") {
      defaultQ.push(
        builder
          .push(["documentNumber", value ?? ""], { like: "both" })
          .and()
          .push([
            "customer.secureId",
            this.formFind.getFieldValue("customerName"),
          ])
          .build()
      );
    } else if (type === "init") {
      defaultQ.push(builder.push(["customer.secureId", value ?? ""]).build());
    }

    params.search = defaultQ.join(builder.AND);

    this.loading.documentNumber = true;
    invoiceServices
      .getListInvoiceAR(params)
      .then(response => {
        this.dataDoOrInvoiceNumber = response.data;
      })
      .finally(() => (this.loading.documentNumber = false));
  }

  /**
   * @param value customerId | documentNumber
   * @param type search | init
   * @description
   * value as customer id if type init.
   * value as documentNumber if type search
   */
  getDoNumber(value: string, type: "search" | "init") {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "",
      customerId: this.formFind.getFieldValue("customerName"),
    };
    if (type === "search") {
      params.search = value ?? "";
    }
    this.loading.documentNumber = true;
    salesOrderServices
      .getListAvailableDeliveryOrderForReturnCust(params)
      .then(res => {
        this.dataDoOrInvoiceNumber = res.data;
      })
      .catch(() => (this.loading.documentNumber = false))
      .finally(() => (this.loading.documentNumber = false));
  }

  changeDropdown(value) {
    if (!value) this.resetForm();
  }

  disabledDate(current) {
    return current < moment().subtract(1, "days").endOf("day");
  }
  searchDocumentNumber(value) {
    debounce(() => {
      if (
        this.formFind.getFieldValue("returnFrom") === RETURN_FROM.DELIVERY_ORDER
      ) {
        this.getDoNumber(value, "search");
      } else {
        this.getInvoiceNumber(value, "search");
      }
    });
  }
  generateData(res, returnFrom) {
    let dataLoop = [] as any[];
    if (returnFrom === RETURN_FROM.INVOICE) {
      dataLoop = res.invoiceARLines.filter(
        item => item.deliveryOrderLineQtyReturn < item.deliveryOrderLineQty
      );
    } else if (returnFrom === RETURN_FROM.DELIVERY_ORDER) {
      dataLoop = res.deliveryOrderLines.filter(
        item => item.qtyReturned < item.qty
      );
    }
    this.dataFind.customerId = res.customerId;
    this.dataFind.branchId = res.branchWarehouseId;
    this.formDetail.setFieldsValue({
      custName: res.customerName,
      salesOrderNumber: res.salesOrderNo,
      branch: res.branchWarehouse
        ? res.branchWarehouse
        : res.branchWarehouseName,
    });
    if (returnFrom == RETURN_FROM.DELIVERY_ORDER) {
      this.formDetail.setFieldsValue({
        deliveryOrderNumber: res.documentNumber,
        deliveryOrderDate: moment(res.date).format(),
      });
      this.dataFind.deliveryOrderId = res.id;
    }
    if (returnFrom == RETURN_FROM.INVOICE) {
      this.formDetail.setFieldsValue({
        invoiceDate: res.invoiceDate,
        invoiceNumber: res.documentNumber,
      });
      this.dataFind.invoiceARId = res.id;
    }
    this.dataListItems = [];
    dataLoop.forEach((element, index) => {
      const qty: number = element.qtyPick ? element.qtyPick : element.qty;
      let obj = {
        doNumber: element.documentReference,
        productCode: element.productCode,
        qtyDelivery: qty,
        price:
          returnFrom == RETURN_FROM.DELIVERY_ORDER
            ? element.cogsPrice
            : element.price,
        locationReceived: element.location,
        locationId: element.locationId,
        id: element.id,
        key: index,
        optionCustom: [
          {
            name: "location",
            option: [] as DataWarehouseLocation[],
          },
        ],
        qtyAvailableReturn: element.qtyAvailableForReturn || 0,
      };
      this.dataListItems.push(obj);
      this.getListOfInventoryLine(
        element.productId,
        element.productUomId ? element.productUomId : element.uomId,
        index
      );
    });
  }
  getListOfInventoryLine(productId, uomId, key) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    params.search = `product.secureId~*${productId}*_AND_uom.secureId~*${uomId}*_AND_warehouseLocation.warehouse.branchWarehouse.secureId~*${this.dataFind.branchId}*_AND_reserved>0`;
    logisticServices.listOfInventory(params).then(res => {
      const listDropdown = [] as Dropdown[];
      res.data.forEach((item, index) => {
        listDropdown.push({
          key: index,
          name: item.warehouseLocationName,
          id: item.warehouseLocationId,
          available: item.available,
        });
      });
      this.dataListItems[key].optionCustom[0].option = listDropdown;
      if (listDropdown.length === 1) {
        this.dataListItems[key].locationId = listDropdown[0].id;
        this.dataListItems[key].location = listDropdown[0].name;
      }
    });
  }
  resetForm() {
    this.formDetail.setFieldsValue({
      custName: "",
      salesOrderNumber: "",
      returnNumber: "",
      returnDate: "",
    });
    this.dataListItems = [];
  }
  getSalesOrderTracking(valueSearch: string) {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) {
      params.search = `documentReference~*${valueSearch}*_AND_state~Delivered_AND_salesOrder.state!Returned`;
    } else {
      params.search = `state~Delivered_AND_salesOrder.state!Returned`;
    }
    this.loadingSales = true;
    salesOrderServices
      .getListSalesOrderTracking(params)
      .then(data => {
        data.data.forEach((dataObject, index) => (dataObject.key = index));
        this.dataListSalesOrder = data.data;
      })
      .finally(() => (this.loadingSales = false));
  }
  handleInput(
    value,
    key,
    objectColInput,
    objectColInputName: string,
    onEvt: string
  ) {
    let formatedVal: string | number = value;

    if (onEvt === "onBlur") {
      formatedVal = currencyFormat(value);
      this.calculationData += 1;
    } else if (onEvt === "onFocus") {
      formatedVal = changeCurrencytoNumeric(value);
    }
    this.dataListItems[key][objectColInput.name] = formatedVal;
    this.dataListItems = this.dataListItems.slice();
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  findData(): void {
    this.formFind.validateFields((err, res) => {
      if (!err) {
        this.formDetail.setFieldsValue({
          returnNumber: "",
          returnDate: moment(),
          refundOption: "",
          custName: "",
          salesOrderNumber: "",
          deliveryOrderNumber: "",
          invoiceNumber: "",
          branch: "",
          deliveryOrderDate: "",
          invoiceDate: "",
          notes: "",
        });
        this.getDetailDataFind(res.returnFrom, res.documentNumber);
      }
    });
  }
  getDetailDataFind(type, id) {
    if (type === RETURN_FROM.INVOICE) {
      invoiceServices.getDetailInvoiceAR(id).then(res => {
        this.generateData(res, RETURN_FROM.INVOICE);
      });
    } else {
      deliveryOrderServices.getDetailDeliveryOrder(id).then(res => {
        this.generateData(res, RETURN_FROM.DELIVERY_ORDER);
      });
    }
  }
  cancel(): void {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push("/logistic/returnfromcustomer");
      },
      onCancel() {
        return;
      },
    });
  }
  save(): void {
    this.formFind.validateFields((err, value) => {
      if (!err) {
        this.formDetail.validateFields((err, item) => {
          if (!err) {
            const dataPost: RequestReturnFromCustomer = {
              customerId: this.dataFind.customerId,
              deliveryOrderId: this.dataFind.deliveryOrderId,
              invoiceARId: this.dataFind.invoiceARId,
              returnDate: moment().format(), // hardcoded into current local time
              returnFrom:
                value.returnFrom === RETURN_FROM.DELIVERY_ORDER
                  ? "Delivery Order"
                  : "Invoice",
              notes: item.notes || "",
              returnItems: [],
            };
            if (value.returnFrom === RETURN_FROM.INVOICE) {
              dataPost.refundOptions =
                item.refundOption === REFUND_OPTIONS.CREDIT_MEMO
                  ? "Credit Memo"
                  : "Refund";
            }

            const dataArrItem: ReturnItems[] = [];
            this.dataListItems.forEach(item => {
              const objData: ReturnItems = {
                locationId: item.locationId,
                price: item.price || 0,
                qtyReturn: item.qtyReturn
                  ? changeCurrencytoNumeric(item.qtyReturn)
                  : 0,
                id: "",
                deliveryOrderLineId: "",
                invoiceARLineId: "",
              };
              if (value.returnFrom === RETURN_FROM.DELIVERY_ORDER) {
                objData.deliveryOrderLineId = item.id;
              } else {
                objData.invoiceARLineId = item.id;
              }
              dataArrItem.push(objData);
            });
            dataPost.returnItems = dataArrItem;

            switch (this.mode) {
              case Mode.CREATE:
                this.loading.save = true;
                logisticServices
                  .createReturnFromCustomer(dataPost)
                  .then(item => {
                    this.$notification.success({
                      description: Messages.CREATE_SUCCESS,
                      message: "Success",
                      duration: 30,
                    });
                    this.$notification.success({
                      description: item["returnNumber"],
                      message: "Success",
                      duration: 30,
                    });

                    this.$router.push("/logistic/returnfromcustomer");
                  })
                  .catch(e => {
                    this.$notification.error({
                      description: Messages.CREATE_FAIL,
                      message: "Error",
                      duration: 30,
                    });

                    this.$notification.error({
                      description: e.details,
                      message: "Error",
                      duration: 30,
                    });

                    this.loading.save = false;
                  })
                  .finally(() => (this.loading.save = false));
                break;
              case Mode.EDIT:
                break;
              default:
                break;
            }
          }
        });
      }
    });
  }

  getDetailDraftReturnItem() {
    salesOrderServices
      .getDetailDraftReturnItem(this.$route.params.id)
      .then(res => {
        this.journalNumber.name = res.journalNumber;
        this.journalNumber.id = res.journalId;
        this.formFind.setFieldsValue({
          customerName: res.customerName,
          returnFrom: res.returnFrom,
        });
        if (res.returnFrom === "Delivery Order") {
          this.formFind.setFieldsValue({
            documentNumber: res.deliveryOrderNumber,
          });
        } else {
          this.formFind.setFieldsValue({
            documentNumber: res.invoiceNumber,
          });
        }
        this.formDetail.setFieldsValue({
          custName: res.customerName,
          salesOrderNumber: res.salesOrderNumber,
          deliveryOrderNumber: res.deliveryOrderNumber,
          journalNumber: res.journalNumber,
          returnDate: res.returnDate,
          returnNumber: res.returnNumber,
          deliveryOrderDate: res.deliveryOrderDate,
          branch: res.branchName,
          notes: res.notes || "",
        });
        res.returnItems.forEach((element, index) => {
          const availableReturn: number = new Decimal(element.qty || 0)
            .minus(element.qtyReturn || 0)
            .toNumber();
          this.dataListItems.push({
            productCode: element.productCode,
            qtyDelivery: element.qty,
            price: element.price,
            locationReceived: element.location,
            total: element.returnTotal,
            qtyReturn: element.qtyReturn,
            key: index,
            disabledInput: true,
            disabledSelect: true,
            qtyAvailableReturn:
              element?.qtyAvailableForReturn || availableReturn,
          });
        });
      });
  }

  beforeCreate(): void {
    this.formFind = this.$form.createForm(this, { name: "formFind" });
    this.formDetail = this.$form.createForm(this, { name: "formDetail" });
  }

  created(): void {
    this.mode = this.$route.meta.mode;
  }

  mounted(): void {
    this.formFind.setFieldsValue({
      returnFrom: RETURN_FROM.DELIVERY_ORDER,
    });
    this.getCustomerList();
    if (this.mode === Mode.EDIT) {
      this.disableData = true;
      this.getDetailDraftReturnItem();
    } else this.disableData = false;
  }

  getCustomerList(): void {
    this.customerOption.fetching = true;
    const param: RequestQueryParamsModel = {
      limit: 20,
      page: 0,
      search: `customer~true`,
    };
    if (this.customerOption.search) {
      param.search += `_AND_firstName~*${this.customerOption.search}*_OR_lastName~*${this.customerOption.search}*_OR_phoneNumber~*${this.customerOption.search}*_OR_email~*${this.customerOption.search}*_OR_customerNumber~*${this.customerOption.search}*`;
    }
    contactServices
      .listContactData(param)
      .then(res => {
        this.customerOption.data = res.data;
      })
      .finally(() => (this.customerOption.fetching = false));
  }

  searchCustomer(value: string): void {
    debounce(() => {
      this.customerOption.search = value;
      this.getCustomerList();
    });
  }

  changeReturnFrom(value: string): void {
    this.formFind.setFieldsValue({
      documentNumber: "",
      returnFrom: value,
    });
    switch (value) {
      case RETURN_FROM.DELIVERY_ORDER:
        this.formRuleResult.invoiceNumber.show = false;
        this.formRuleResult.invoiceDate.show = false;
        this.formRuleResult.refundOption.show = false;
        this.getDoNumber(this.formFind.getFieldValue("customerName"), "init");
        if (
          this.columnsTable.findIndex(item => item.dataIndex === "doNumber") !=
          -1
        )
          this.columnsTable.splice(
            this.columnsTable.findIndex(item => item.dataIndex === "doNumber"),
            1
          );
        break;
      case RETURN_FROM.INVOICE:
        this.formRuleResult.invoiceNumber.show = true;
        this.formRuleResult.invoiceDate.show = true;
        this.formRuleResult.refundOption.show = true;
        if (
          this.columnsTable.findIndex(item => item.dataIndex === "doNumber") ==
          -1
        )
          this.columnsTable.splice(0, 0, {
            title: "Delivery Order Number",
            dataIndex: "doNumber",
            key: "doNumber",
            width: 300,
            scopedSlots: { customRender: "doNumber" },
          });

        this.getInvoiceNumber(
          this.formFind.getFieldValue("customerName"),
          "init"
        );
        break;
      default:
        break;
    }
  }

  handleSelect(value, key, col): void {
    console.log("value: ", value);
    console.log("key: ", key);
    console.log("col: ", col);
  }

  handleSearchSelectTable(value, key, col): void {
    console.log("value: ", value);
    console.log("key: ", key);
    console.log("col: ", col);
  }
}
